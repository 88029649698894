<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{$t('common.작성자')}} : {{ board.writer.name }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.작성일')}} : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.조회수')}} : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="board.content"></div>
                </td>
            </tr>
        </table>

        <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table>

        <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="grey-7" class="w-md-100 mw-md-100px">수정</v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">삭제</span></v-btn>
                <v-btn large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">답글</span></v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.go(-1)" large outlined color="grey-d" class="w-md-100 mw-md-100px"><span class="grey-7--text">{{ $t("common.list") }}</span></v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import CommentList from "../../comment-list.vue";

export default {
    components: {
        CommentList,
    },
    props: {
        board: { type: Object, default: null },
    },
    data() {
        return {
            data: undefined,
            show: false,
        };
    },
    watch: {
        board() {
            if (this.board) this.show = true;
        },
    },
};
</script>
